import React, { useState } from "react";
import AdminNavbar from "./AdminNavbar";
import { useNavigate } from "react-router-dom";

const AdminNews = () => {
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    content: "",
    imageUrl: "",
    imageDescription: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // console.log("New News Item:", formData);
      navigate("/admin/news", { state: { formData } });
      const existingNews = JSON.parse(localStorage.getItem("adminNews")) || [];

      // Add the new form data to the existing news array
      const updatedNews = [...existingNews, formData];

      // Store the updated news array back in local storage
      localStorage.setItem("adminNews", JSON.stringify(updatedNews));

      setFormData({
        title: "",
        date: "",
        content: "",
        imageUrl: "",
        imageDescription: "",
      });
      setErrors({});
    }
  };

  return (
    <>
      <AdminNavbar />
      <div className="container">
        <div className="form-admin-blogs">
          <form onSubmit={handleSubmit} className="form-admin-blog">
            <h3> News Form</h3>
            {["title", "date", "content", "imageUrl", "imageDescription"].map(
              (field) => (
                <div className="form-group" key={field}>
                  <label htmlFor={field}>
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>

                  {field === "content" ? (
                    <textarea
                      id={field}
                      name={field}
                      className="form-control"
                      value={formData[field]}
                      onChange={handleChange}
                    />
                  ) : field === "imageUrl" ? (
                    <input
                      type="url" // Ensure it's a URL input field
                      id={field}
                      name={field}
                      className="form-control"
                      placeholder="https://example.com" // Optional placeholder to guide user
                      value={formData[field]}
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      type={field === "date" ? "date" : "text"}
                      id={field}
                      name={field}
                      className="form-control"
                      value={formData[field]}
                      onChange={handleChange}
                    />
                  )}

                  {errors[field] && (
                    <small className="text-danger">{errors[field]}</small>
                  )}
                </div>
              )
            )}

            <div className="submit-btn">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>{" "}
    </>
  );
};

export default AdminNews;
