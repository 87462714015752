import React from "react";
import { Table, Tooltip } from "react-bootstrap";
import SevenDays from "../Common/Graph/SevenDays";
import AdminNavbar from "./AdminNavbar";
import "./Admin.css";

const Admin = () => {
  return (
    <div className="admin-dashboard-main-pg">
      <AdminNavbar />
      <div className="container-fluid">
        <div className="admin-dashboard">
          <div className="row ">
            <div className="col-lg-2">
              <div className="admin-dashboard-left">
                <ul>
                  <li>
                    <a href="">Home</a>
                  </li>
                  <li>
                    <a href="">Home</a>
                  </li>
                  <li>
                    <a href="">Home</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="admin-dashboard-right">hello admin</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
