import { NavLink } from "react-router-dom";
import Searchbar from "../Common/SearchBar/Searchbar";
import Logo from "../Assets/img/logo.png";

const AdminNavbar = () => {
  return (
    <div className="navbar-bg inter-font-500">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1728986475/rwa-guide-logo_3_txmbni.png"
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink className="nav-link" to="/admin/dashboard">
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/coin">
                  Coin
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/user">
                  User
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/news/add">
                  Add News
                </NavLink>
              </li>{" "}
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/news">
                  News
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" href="/admin/coin">
                  Coin
                </NavLink>
              </li> */}
            </ul>
            <form className="d-flex search-input me-2" role="search">
              <Searchbar />
            </form>
            {/* <button className="btn btn-outline-success me-2" type="submit">
          Login
        </button>
        <button className="btn btn-outline-success" type="submit">
          Sign Up
        </button> */}
            <button className="btn login-user" type="button">
              <div className="dropdown">
                <ul className="dropdown-menu navbar-dash">
                  <li>
                    <NavLink to="/dashboard">
                      {/* //className="dropdown-item" */}
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink>Logout</NavLink>
                    {/* className="dropdown-item" */}
                  </li>
                </ul>
              </div>

              <NavLink to="/login">
                {/* <i className="fa-solid fa-user fa-lg"> </i> */}
                Login
              </NavLink>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminNavbar;
