import { useLocation } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const AdminNewsSummary = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    const storedNews = JSON.parse(localStorage.getItem("adminNews")) || [];
    setNewsItems(storedNews);
  }, []);

  const handleDelete = (index) => {
    const updatedNews = newsItems.filter((_, i) => i !== deleteIndex);

    localStorage.setItem("adminNews", JSON.stringify(updatedNews));
    setNewsItems(updatedNews);
    setShowModal(false);
    setDeleteIndex(null);
  };
  const openDeleteModal = (index) => {
    setDeleteIndex(index);
    setShowModal(true);
  };
  return (
    <>
      <AdminNavbar />
      <div className="container">
        <h3>News Summary</h3>
        {newsItems.length > 0 ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Content</th>
                <th>Image URL</th>
                <th>Image Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {newsItems.map((news, index) => (
                <tr key={index}>
                  <td>{news.title}</td>
                  <td>{news.date}</td>
                  <td>{news.content}</td>
                  <td>{news.imageUrl}</td>
                  <td>{news.imageDescription}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => openDeleteModal(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No news items available.</p>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Permanent Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This action cannot be undone. Are you sure you want to permanently
            delete this news item?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete Permanently
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminNewsSummary;
